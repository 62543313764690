<template>
  <div>
    <Modal modal-class="modal-import slide-up" ref="modal_import" size="md" :on-hide="onHideImportModal"
           :modal-header-title="$t('hotel.import fee rule')">
      <template v-slot:container>
        <ValidationObserver ref="form_import" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(importUpload)">
            <div class="modal-body">
              <span class="app-text">
                CSVに登録されている全ての料金プランが追加（新規作成）されます。
                既存のプランを変更（削除）する機能はありません。
              </span>
              <HeaderNotice ref="notice"></HeaderNotice>
              <ValidationProvider name="file_import" v-slot="{ errors, invalid, validated }">
                <div :class="['custom-file form-group', invalid && validated ? 'has-error' : '']">
                  <input @change="changeFileImport" type="file" ref="file_import" class="custom-file-input"
                         id="customFile">
                  <span class="custom-file-label" for="customFile">{{ importData.fileName }}</span>
                  <label v-if="invalid && validated" class="error app-input-error-label-2">{{ errors[0] }}</label>
                </div>
              </ValidationProvider>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button v-if="importData.file" :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                全て追加（新規作成）
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <BasicTable ref="table"
                :table-name="tableName"
                :settingColumns="columns"
                :actions="actions"
                :settingTools="tools"
                :setting-apis="apis"></BasicTable>
  </div>
</template>
<script>
import BasicTable from '@components/_common/List/BasicTable';

var getFeeRateFromEntry = (entry, dateType) => {
  let value = ''
  _.forEach(entry.fee_rule_details, feeRateDetail => {
    if (dateType === feeRateDetail.date_type) {
      value = feeRateDetail.fee_rate
    }
  })
  return value;
}

export default {
  data() {
    return {
      tableName: this.$t('hotel.fee rule list'),
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.HOTEL_FEE_RULE_LIST,
          params: {"filters[hotel_id][equal]": this.$route.params.id}
        },
        delete: {
          endpoint: this.$consts.API.ADMIN.HOTEL_FEE_RULE_DELETE,
        }
      },
      actions: {
        createEntry: () => this.$router.pushByName(this.$consts.ROUTES.ADMIN.HOTEL_FEE_RULE_CREATE),
        importEntries: this.importEntries,
        exportEntries: this.exportEntries,
        others: [
          {
            label: this.$t('common.view'),
            routerLink: entry => {
              return {
                name: this.$consts.ROUTES.ADMIN.HOTEL_FEE_RULE_EDIT,
                params: {fee_rule_id: entry.id}
              }
            },
            contentHtml: entry => `<div class="btn-tool default text-center">` + this.$t('common.view') + `</div>`,
          },
          {
            label: 'コピー',
            routerLink: entry => {
              return {
                name: this.$consts.ROUTES.ADMIN.HOTEL_FEE_RULE_COPY,
                params: {fee_rule_id: entry.id}
              }
            },
            contentHtml: entry => `<div class="btn-tool default text-center">コピー</div>`,
          },
          {
            label: this.$t('common.delete'),
            click: entry => this.$refs.table.deleteEntry(entry),
            contentHtml: entry => `<div class="btn-tool delete"></div>`,
          }
        ]
      },
      tools: {
        search: {
          // reset: true,
          filters: [
            {
              name: 'id',
              type: 'not_equal',
            },
            {
              name: 'start_date',
              rules: 'required_if:end_date',
              type: 'in_date_range_start',
              label: this.$t('common.start_date'),
              // uiType: 'datepicker',
            },
            {
              name: 'end_date',
              rules: 'required_if:start_date',
              type: 'in_date_range_end',
              label: this.$t('common.end_date'),
              // uiType: 'datepicker',
            },
            {
              name: 'room_type_id',
              type: 'equal',
              label: this.$t('common.hotel_room_type'),
              // uiType: 'select',
            },
            {
              name: 'people',
              type: 'equal',
              label: this.$t('common.hotel_people_fee_rate'),
              // uiType: 'input',
            },
          ]
        }
      },
      importData: {
        file: null,
        fileName: null
      },
      hotelRoomTypes: [],
      entrySelectedDelete: {},
      hotel: {},
      columns: [
        //{name: 'id', label: 'ID', fitSize: true},
        {name: 'name', label: this.$t('hotel.plan name'), sortable: false},
        {name: 'start_date', label: this.$t('common.start_date'), computed: entry => this.formatDate(entry.start_date)},
        {name: 'end_date', label: this.$t('common.end_date'), computed: entry => this.formatDate(entry.end_date)},
        {name: 'room_type.name', label: this.$t('common.hotel_room_type'), sortable: false},
      ]
    }
  },
  components: {
    BasicTable
  },
  mounted() {
    this.$request.get(this.$consts.API.ADMIN.HOTEL_DETAIL(this.$route.params.id), null, {
      noLoadingEffect: true,
      handleNotFound: true
    }).then(res => {
      if (!res.hasErrors()) {
        this.hotel = res.data.data
        this.$store.commit('setting/updateBreadCrumb', {hotel: this.hotel.name})
        this.$request.get(this.$consts.API.ADMIN.HOTEL_ROOM_TYPE_SELECT(this.hotel.id)).then(res => {
          if (!res.hasErrors()) {
            this.hotelRoomTypes = res.data.data
            // this.setOptionFilter(this.tools, 'room_type_id', 'equal', this.hotelRoomTypes);
          }
        })
      }
    })
  },
  methods: {
    importEntries() {
      this.$refs.modal_import.show();
    },
    exportEntries() {
      this.$request.get(this.$consts.API.ADMIN.DOWNLOAD_CSV_HOTEL_FEE_RULE).then(res => {
        if (!res.hasErrors()) {
          this.downloadFromUrl(res.data.url)
        } else {
          this.$appNotice.error()
        }
      })
    },
    changeFileImport() {
      const file = this.$refs.file_import.files[0]
      console.log('import with file type: ' + file.type)
      if (file && (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel')) {
        this.importData = {
          file, fileName: file.name
        }
        this.$refs.notice.clear()
        this.$refs.form_import.reset();
      } else {
        this.$refs.form_import.setErrors({
          file_import: [
            this.$t('common.file ext err')
          ]
        })
      }
    },
    importUpload() {
      const file = this.importData.file
      let formData = new FormData
      formData.append('file', file)
      formData.append('hotel_id', this.hotel.id)
      this.$request.post(this.$consts.API.ADMIN.IMPORT_HOTEL_FEE_RULE, formData).then(res => {
        if (!res.hasErrors()) {
          this.$refs.notice.clear();
          this.$refs.modal_import.hide();
          this.$appNotice.success(this.$t('common.import success'))
          this.$refs.table.getEntries()
        } else {
          try {
            this.$refs.notice.setError(res.data[0].errors[0])
          } catch (e) {
            this.$appNotice.error(this.$t('common.import err'))
          }
        }
        this.$refs.form_import.reset();
        this.resetImportForm();
      })
    },
    onHideImportModal() {
      this.resetImportForm();
      this.$refs.notice.clear()
    },
    resetImportForm() {
      this.importData = {
        file: null,
        fileName: null
      }
      this.$refs.form_import.reset();
      $(this.$refs.file_import).val('');
    }
  }
}
</script>
