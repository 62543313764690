var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Modal',{ref:"modal_import",attrs:{"modal-class":"modal-import slide-up","size":"md","on-hide":_vm.onHideImportModal,"modal-header-title":_vm.$t('hotel.import fee rule')},scopedSlots:_vm._u([{key:"container",fn:function(){return [_c('ValidationObserver',{ref:"form_import",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
var valid = ref.valid;
var validated = ref.validated;
return [_c('form',{attrs:{"novalidate":"","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.importUpload)}}},[_c('div',{staticClass:"modal-body"},[_c('span',{staticClass:"app-text"},[_vm._v(" CSVに登録されている全ての料金プランが追加（新規作成）されます。 既存のプランを変更（削除）する機能はありません。 ")]),_c('HeaderNotice',{ref:"notice"}),_c('ValidationProvider',{attrs:{"name":"file_import"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('div',{class:['custom-file form-group', invalid && validated ? 'has-error' : '']},[_c('input',{ref:"file_import",staticClass:"custom-file-input",attrs:{"type":"file","id":"customFile"},on:{"change":_vm.changeFileImport}}),_c('span',{staticClass:"custom-file-label",attrs:{"for":"customFile"}},[_vm._v(_vm._s(_vm.importData.fileName))]),(invalid && validated)?_c('label',{staticClass:"error app-input-error-label-2"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)})],1),_c('div',{staticClass:"modal-footer"},[_c('button',{class:['btn btn-default btn-cons no-margin inline btn-modal-cancel'],attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")]),(_vm.importData.file)?_c('button',{class:['btn btn-cons inline btn-modal-submit', 'btn-complete'],attrs:{"disabled":!valid && validated,"type":"submit"}},[_vm._v(" 全て追加（新規作成） ")]):_vm._e()])])]}}])})]},proxy:true}])}),_c('BasicTable',{ref:"table",attrs:{"table-name":_vm.tableName,"settingColumns":_vm.columns,"actions":_vm.actions,"settingTools":_vm.tools,"setting-apis":_vm.apis}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }